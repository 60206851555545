<template>
	<div>
		<div class="row box">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.conditions_contrats') }}</h3>

				<CustomTable
					id_table="season_contract_config_conditions"
					ref="table_conditions"
					:items="conditions_formatted"
					:busy="table_busy_conditions"
					primaryKey="contractconfigglobaltypemonte_id"
					:columsAdd="typeMonteAddColumns"
					:rawColumns="columsAdd"
					:externSlotColumns="['available_ca']"
				>
				<template v-slot:[`custom-slot-cell(available_ca)`]="{ data }">
					<b-form-checkbox
						class="custom-control custom-checkbox float-right mr-2"
						v-model="data.contractconfigglobaltypemonte_available_ca"
						@input="switchAvailableCa(data)"
						name="check-default-ca" 
						switch
					>
					</b-form-checkbox>
				</template>
				</CustomTable>
			</div>
		</div>

		

		<b-modal ref="modalAddContractConfigCondition" size="xl" hide-footer>
			<template v-slot:modal-title>
				{{ $t("monte.ajouter_une_condition") }}
			</template>

			<ConditionsForm ref="ConditionsForm" :season_id="season_id" :type_monte="type_monte" :processing.sync="processing" :contractconfigglobal_id="contractconfigglobal_id" :conditions="conditions_monte" :contract_type_monte="current_contract_type_monte"></ConditionsForm>

			<div class="col-8 m-auto">
				<b-button block pill variant="primary" @click.prevent="onSend"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import _sortBy from "lodash/sortBy"
	import { EventBus } from 'EventBus'

	export default {
		name: "SaisonConditionsList",
		props: ['season_id'],
		mixins: [Navigation, ContractMixin],
		data () {
			return {
				events_tab: {
					'TableAction::goToAddConditionsToContractConfigGlobal': this.addContratConfigGlobalConditions,
					'TableAction::goToEditConditionsContractConfigGlobal': (params) => {
						this.current_contract_type_monte = this.conditions.filter(elem => elem.contractconfigglobaltypemonte_id == params.contractconfigglobaltypemonte_id)[0]
						this.openModalConditionForm()
					},
					'TableAction::goToDistributeConditionsContractConfigGlobal': (lines) => {
						this.distributeConditions(lines.contractconfigglobaltypemonte_id)
					},
					'TableAction::goToDeleteConditionsContractConfigGlobal': this.goToDeleteConditionsContractConfigGlobal,
				},
				local_season_id: null,
				processing_conditions: false,
				details: [],
				columsAdd: [],
				all_type_monte: [],
				contractconfigglobal_id: null,
				processing: false,
				conditions: [],
				conditions_monte: [],
				type_monte: [],
				current_contract_type_monte: {},
				table_busy_conditions: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {

				this.local_season_id = this.$sync.replaceWithReplicated('season', this.season_id)

				this.table_busy_conditions = true
				await this.loadConditionsSeasonContractConfig()

				this.table_busy_conditions = false
			},
			async loadConditionsSeasonContractConfig(){
				let contract_config = await this.loadConditionsGlobal(this.local_season_id)
				this.contractconfigglobal_id = contract_config.contractconfigglobal_id
				this.conditions = contract_config.type_monte
			},
			back_action(){
				// this.$router.push({name: "ContractConfigStallionList", params: {horse_id: this.horse.horse_id}})
			},
			async addContratConfigGlobalConditions() {
				this.current_contract_type_monte = {}
				
				this.openModalConditionForm()
			},
			async openModalConditionForm(){
				this.conditions_monte = await this.getConditionsMonte()
				this.type_monte = await this.getTypeMonte()

				this.$refs.modalAddContractConfigCondition.show()
			},
			async onSend() {
			    let return_send = await this.$refs['ConditionsForm'].checkForm()

			    if(return_send)  {
			        this.$refs["modalAddContractConfigCondition"].hide()
			        this.table_busy = true
			        this.contractConfigTypeMonte = []
					this.$refs["table_conditions"].unselectAll()
			        await this.init_component()
			    }
			},


			async distributeConditions(contractconfigglobaltypemonte_id){
				this.$router.push({ 
					name: 'distributeConditionsContract', 
					params: { 
						contractconfigglobaltypemonte_id: contractconfigglobaltypemonte_id
					}
				})
			},
			switchAvailableCa(model) {
				this.updateContractConfigGlobalConditionAvailableCa(model.contractconfigglobaltypemonte_id, model.contractconfigglobaltypemonte_available_ca)
			},

			async goToDeleteConditionsContractConfigGlobal(ccc_global) {
				await this.deleteConditionsContractConfigGlobal(ccc_global.contractconfigglobaltypemonte_id)
				EventBus.$emit('TableAction::stopSpin')
				this.$refs["table_conditions"].unselectAll()
				this.init_component()
			}
		},
		computed: {
			hasDedicatedCa() {
                return this.$store.state.userAccess.hasDedicatedClientArea
			},
			conditions_formatted(){
				let tab = []

				for (let i = 0; i < this.conditions.length; i++) {
					const element = this.conditions[i];
					element.type_monte.contracttypemonte_label = this.getTrad(element.type_monte.contracttypemonte_label)
					element.country = element.pays.length > 0
					element.pays_tab = element.pays.map(p => p.globaltypemontepays_pays)
					tab.push(element)
				}

				return _sortBy(tab, ['contractconfigglobaltypemonte_label'])
			},
			typeMonteAddColumns() {
				if(!this.hasDedicatedCa) return this.columsAdd
				return [...this.columsAdd, 'available_ca']
			},
		},
		watch: {
			
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
			ConditionsForm : () => import('@/components/Saison/ConditionsForm'),
			DecimalInput: () => import('GroomyRoot/components/Inputs/DecimalInput')
		}
	}
</script>
